import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as actions from '../actions';
import initialState from '../store/initialState';

export const calcForm = handleActions(
  {
    [actions.setCalcSelectionsState](state, { payload }) {
      return { ...state, [payload.name]: payload.value };
    },
    [actions.setCalcTermState](state, { payload }) {
      return { ...state, term: payload.term, creditRate: payload.rate };
    },
    [actions.setCalcFirstPaymentState](state, { payload }) {
      return { ...state, firstPayment: payload };
    },
    [actions.setCreditAmountState](state, { payload }) {
      return { ...state, creditAmount: payload };
    },
    [actions.setEmptyCalcState](state) {
      return {
        ...state,
        has_bill_of_sale: true,
        official_income: true,
        calc_user_name: '',
        calc_phone_number: ''
      };
    }
  },
  initialState.calcForm
);

export const searchForm = handleActions(
  {
    [actions.switchTab](state, { payload }) {
      return { ...state, currentTabId: payload };
    },
    [actions.setSearchSort](state, { payload }) {
      return { ...state, currentSortType: payload };
    },
    [actions.showPopupHeader](state) {
      return { ...state, 'popup-header': true };
    },
    [actions.hidePopupHeader](state) {
      return { ...state, 'popup-header': false };
    },
    [actions.setCurrentCity](state, { payload }) {
      return { ...state, currentCity: payload };
    },
    [actions.setSearchValues](state, { payload }) {
      return { ...state, values: payload };
    },
    [actions.setResetPopupHandler](state, { payload }) {
      return { ...state, resetPopupHandler: payload };
    },
    [actions.setLocationsResetButtonIsDisabled](state, { payload }) {
      return { ...state, locationsResetButtonIsDisabled: payload };
    },
    [actions.setResetButtonIsDisabled](state, { payload }) {
      return { ...state, resetButtonIsDisabled: payload };
    },
    [actions.setChosenLocationNames](state, { payload }) {
      return { ...state, chosenLocationNames: payload };
    },
    [actions.setLocationIds](state, { payload }) {
      return { ...state, locationIds: payload };
    },
    [actions.setCurrentState](state, { payload }) {
      return { ...state, currentState: payload };
    },
    [actions.setIsLoadingResultByNumber](state, { payload }) {
      return { ...state, isLoadingResultByNumber: payload };
    },
    [actions.setResultByNumber](state, { payload }) {
      return { ...state, resultByNumber: payload };
    },
    [actions.setCategoryIds](state, { payload }) {
      return { ...state, categoryIds: payload };
    }
  },
  initialState.searchForm
);

export const authModal = handleActions(
  {
    [actions.toggleAuthModal](state, { payload }) {
      const updatedState =
        typeof payload === 'boolean' ? payload : !state.isVisible;
      return { ...state, isVisible: updatedState };
    }
  },
  initialState.authModal
);

export const drawerUI = handleActions(
  {
    [actions.toggleDrawer](state, { payload }) {
      const updatedState =
        typeof payload === 'boolean' ? payload : !state.isVisible;
      return { ...state, isVisible: updatedState };
    }
  },
  initialState.drawerUI
);

const errorUI = handleActions(
  {
    [actions.setError](state, { payload }) {
      return { ...state, isError: payload };
    }
  },
  initialState.errorUI
);

const blockerDetector = handleActions(
  {
    [actions.setBlockerDetector](state, { payload }) {
      return { ...state, isDetected: payload };
    }
  },
  initialState.blockerDetector
);

export const sideMenuModal = handleActions(
  {
    [actions.toggleSideMenuModal](state, { payload }) {
      const updatedState =
        typeof payload === 'boolean' ? payload : !state.isVisible;
      return { ...state, isVisible: updatedState };
    }
  },
  initialState.sideMenuModal
);

export const addNewItemModal = handleActions(
  {
    [actions.toggleAddNewItemModal](state, { payload }) {
      const updatedState =
        typeof payload === 'boolean' ? payload : !state.isVisible;
      return { ...state, isVisible: updatedState };
    },
    [actions.toggleAuthWindow](state, { payload }) {
      const updatedState =
        typeof payload === 'boolean' ? payload : !state.isOpenAuthWindow;
      return { ...state, isOpenAuthWindow: updatedState };
    },
    [actions.setCreationResetButtonIsDisabled](state, { payload }) {
      return { ...state, creationResetButtonIsDisabled: payload };
    }
  },
  initialState.addNewItemModal
);

export const promotionModalView = handleActions(
  {
    [actions.togglePromotionModalView](state, { payload }) {
      const updatedState =
        typeof payload === 'boolean' ? payload : !state.isVisible;
      return { ...state, isVisible: updatedState };
    }
  },
  initialState.promotionModalView
);

export const restoreAlertModal = handleActions(
  {
    [actions.toggleRestoreAlertModal](state, { payload }) {
      const updatedState =
        typeof payload === 'boolean' ? payload : !state.isVisible;
      return { ...state, isVisible: updatedState };
    }
  },
  initialState.restoreAlertModal
);

export const successPromotionModalView = handleActions(
  {
    [actions.openSuccessPromotionAlertModal](state, { payload }) {
      return { isVisible: true, name: payload };
    }
  },
  initialState.successPromotionModalView
);

export const failurePaymentModalView = handleActions(
  {
    [actions.openFailurePaymentAlertModal](state, { payload }) {
      return { isVisible: payload.isVisible, name: payload.name };
    }
  },
  initialState.failurePaymentModalView
);

export const itemsBalanceModalView = handleActions(
  {
    [actions.openItemsBalanceModal](state, { payload }) {
      return { isVisible: payload };
    }
  },
  initialState.itemsBalanceModalView
);

export const buyItemsModalView = handleActions(
  {
    [actions.openBuyItemsModal](state, { payload }) {
      return { isVisible: payload };
    }
  },
  initialState.buyItemsModalView
);

export const walletPayModalView = handleActions(
  {
    [actions.openWalletPayModal](state, { payload }) {
      return { isVisible: payload };
    }
  },
  initialState.walletPayModalView
);

export const terminalModalView = handleActions(
  {
    [actions.openTerminalModal](state, { payload }) {
      return { isVisible: payload };
    }
  },
  initialState.terminalModalView
);

export const personalAccountModalView = handleActions(
  {
    [actions.openPersonalAccountModal](state, { payload }) {
      return { isVisible: payload };
    }
  },
  initialState.personalAccountModalView
);

export const profilePromotionItemId = handleActions(
  {
    [actions.setProfilePromotionItemId](state, { payload }) {
      return { profileItemId: payload };
    }
  },
  initialState.profilePromotionItemId
);

export const profileEditSuccessMessage = handleActions(
  {
    [actions.showProfileEditSuccessMessage](state, { payload }) {
      return { isVisible: payload };
    }
  },
  initialState.profileEditSuccessMessage
);

export const profilePromotionItemsModalView = handleActions(
  {
    [actions.toggleProfilePromotionItemsModal](state, { payload }) {
      return { isVisible: payload };
    }
  },
  initialState.profilePromotionItemsModalView
);

export const packagePayModalView = handleActions(
  {
    [actions.openPackagePayModal](state, { payload }) {
      return { isVisible: payload };
    }
  },
  initialState.packagePayModalView
);

export default combineReducers({
  authModal,
  addNewItemModal,
  sideMenuModal,
  promotionModalView,
  restoreAlertModal,
  drawerUI,
  searchForm,
  errorUI,
  blockerDetector,
  calcForm,
  successPromotionModalView,
  failurePaymentModalView,
  itemsBalanceModalView,
  buyItemsModalView,
  walletPayModalView,
  terminalModalView,
  personalAccountModalView,
  profilePromotionItemId,
  profilePromotionItemsModalView,
  profileEditSuccessMessage,
  packagePayModalView
});
