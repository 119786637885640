export const STATIC_PAGES = {
  ABOUT: '/pages/about',
  ADVERTISE: '/pages/advertise',
  PACKAGES: '/pages/packages',
  PRIVACY: '/pages/privacy',
  PROPOSAL: '/pages/proposal',
  RULES: '/pages/rules',
  TERMS: '/pages/terms-and-conditions'
};

export const STATIC_SLUG = {
  about: 'ABOUT',
  advertise: 'AD',
  packages: 'PACKAGES',
  privacy: 'PRIVACY',
  proposal: 'PROPOSAL',
  rules: 'RULES',
  'terms-and-conditions': 'TERMS'
};

export const MAX_PHOTOS_LENGTH = 30;
export const DEFAULT_PHOTOS_LENGTH = 4;

export const TABLET_BREAKPOINT = 744;
export const DESKTOP_BREAKPOINT = 1280;

export const BUILDING_TYPE_ID = {
  FLAT_ALL: '1',
  FLAT_NEW: '2',
  FLAT_SECONDARY: '3',
  HOUSE: '5',
  OFFICE: '7',
  GARAGE: '8',
  AREA: '9',
  OBJECT: '10'
};

export const MODAL_ANIMATION_TIME = 300;

export const SORT_MODAL_HEIGHT = 300;

export const urlHashes = {
  authentication: '#authentication',
  more: '#more',
  serviceBump: '#service_bump',
  serviceFeature: '#service_feature',
  serviceVip: '#service_vip',
  restore: '#restore',
  personalAds: '#personal_ads',
  limitPayment: '#limit_payment',
  photosGrid: 'photos_grid',
  fullScreenGallery: 'full_screen_gallery'
};

export const shortPromotionHashes = [
  urlHashes.serviceBump,
  urlHashes.serviceFeature,
  urlHashes.serviceVip
];

export const longPromotionHashes = [
  urlHashes.serviceBump,
  urlHashes.serviceFeature,
  urlHashes.serviceVip,
  urlHashes.restore,
  urlHashes.personalAds,
  urlHashes.limitPayment
];

export const MAX_INT_VALUE = 2_147_483_647;
export const CATEGORY_REQUIRING_ROOMS = ['1', '2', '3', '5', '7'];

export const FORM_VALUES = {
  leased: 'isLeased',
  cityId: 'city_id',
  villageId: 'townshipId',
  villageName: 'villageName',
  categoryId: 'categoryId',
  locationId: 'regionId',
  locationIds: 'landmarkIds',
  locationName: 'locationName',
  coordinates: 'coordinates',
  address: 'address',
  roomsAmount: 'rooms',
  area: 'area',
  landArea: 'landArea',
  floor: 'floor',
  totalFloors: 'totalFloors',
  hasRepair: 'hasRepair',
  buildingType: 'buildingType',
  paidDaily: 'paidDaily',
  price: 'price',
  hasBillOfSale: 'hasBillOfSale',
  hasMortgage: 'hasMortgage',
  description: 'description',
  owner: 'owner',
  name: 'name',
  email: 'email',
  phone: 'phone',
  company: 'company',
  code: 'code',
  typeEstate: 'typeEstate',
  images: 'photos',
  categoryValues: 'categoryValues',
  cityHasLocations: 'cityHasLocations'
};

export const DEFAULT_CATEGORY_VALUES = {
  areaUnits: 'm²',
  hasRooms: false,
  hasFloor: false,
  hasRepair: false,
  hasLandArea: false,
  hasBuildingType: false,
  areRoomsRequired: true,
  photosRequired: DEFAULT_PHOTOS_LENGTH
};

export const INITIAL_FORM_VALUES = {
  [FORM_VALUES.cityId]: '1',
  [FORM_VALUES.owner]: 'OWNER',
  [FORM_VALUES.leased]: 'false',
  [FORM_VALUES.hasBillOfSale]: false,
  [FORM_VALUES.hasMortgage]: false,
  [FORM_VALUES.categoryValues]: DEFAULT_CATEGORY_VALUES,
  [FORM_VALUES.cityHasLocations]: true
};

export const PROFILE_FORM_VALUES = {
  name: 'name',
  email: 'email',
  phone: 'phone'
};

export const PROFILE_PASSWORD_FORM_VALUES = {
  currentPassword: 'current_password',
  newPassword: 'new_password',
  confirmPassword: 'confirm_password'
};

export const initialWalletPayValue = '20';
export const walletPayMaxValue = 5000;
export const walletPayPortmanatMaxValue = 1000;

export const TIME_FORMAT = {
  fullDateTime: 'DD MMMM YYYY, HH:mm',
  timeOnly: 'HH:mm',
  fullDateOnly: 'DD MMMM YYYY',
  numDateTime: 'DD.MM.YYYY, HH:mm'
};

export const INITIAL_SEARCH_FORM_VALUES = {
  leased: 'false',
  categoryId: '1',
  cityId: '1'
};

export const REQUEST_STATUSES = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  INVALID: 'INVALID',
  REDIRECT: 'REDIRECT',
  UNHANDLED: 'UNHANDLED'
};

export const CREATION_INVOICE_STATUS = {
  TODAY: 'TODAY',
  POSTPONE: 'POSTPONE'
};

export const INVOICE_STATUS = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  OVERDUE: 'OVERDUE',
  CANCELED: 'CANCELED',
  REVOKED: 'REVOKED'
};

export const PROFILE_BUSINESS_TABS = {
  packages: 'packages',
  business: 'business',
  invoices: 'invoices'
};

export const PROMOTION_SERVICES_ARRAY = ['bump', 'vip', 'feature'];
export const PROMOTION_SERVICES_ARRAY_WITH_PRICE = [
  {
    name: 'bump',
    price: 3
  },
  {
    name: 'vip',
    price: 4
  },
  {
    name: 'feature',
    price: 6
  }
];

export const PROFILE_ROUTES = {
  PROFILE: '/profile',
  SETTINGS: '/profile/settings',
  INFO: '/profile/info',
  EDIT: '/profile/edit',
  PACKAGES: '/profile/packages',
  PACKAGES_PAYMENT: '/profile/packages/payment',
  PAYMENTS: '/profile/payments',
  TRANSACTIONS: '/profile/transactions',
  PAID_ADS_TRANSACTIONS: '/profile/paid_ads_transactions',
  INVOICES: '/profile/invoices'
};
